.ACD-admin-lines-numbers-list {
  margin-bottom: 2em;

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      display: grid;
      grid-template-columns: 1fr max-content;
      gap: 1em;
    }

    .btn {
      align-self: end;
      margin-bottom: 1em;
    }
  }
}

@use "sass:math";

.ACD-status-panel {
  list-style-type: none;
  padding-left: 0;
  margin-top: 1rem;

  li {
    display: grid;
    grid-template-areas:
      "name number"
      "status status";
    grid-template-columns: 1fr max-content;
    grid-column-gap: 1em;
    border-bottom: $border-width solid $border-color;
    padding: math.div($alert-padding-x, 2) math.div($alert-padding-x, 2);

    .name {
      grid-area: name;
    }
    .e164 {
      grid-area: number;
    }
    .status {
      grid-area: status;
      font-size: 0.9em;
    }
  }

  li:first-of-type {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  li:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.endpoint-entry {
  &.READY {
    &,
    td,
    th {
      @include acd_alert("success");
      border-radius: 0;
    }
  }

  &.ALERTING {
    &,
    td,
    th {
      @include acd_alert("warning");
      border-radius: 0;
    }
  }

  &.TALKING {
    &,
    td,
    th {
      @include acd_alert("danger");
      border-radius: 0;
    }
  }

  &.AWAY {
    &,
    td,
    th {
      @include acd_alert("dark");
      border-radius: 0;
    }
  }

  &.PAUSE {
    &,
    td,
    th {
      @include acd_alert("secondary");
      border-radius: 0;
    }
  }

  &.POSTPROCESSING,
  &.POSTPROCESSING_AUTO {
    &,
    td,
    th {
      @include acd_alert("primary");
      border-radius: 0;
    }
  }

  &.BUSY {
    &,
    td,
    th {
      @include acd_alert("info");
      border-radius: 0;
    }
  }
}

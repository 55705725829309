.ACD-login-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;

  form {
    padding: 2em;

    background-color: $light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;

    width: 100%;
    max-width: 35em;

    h1 {
      font-weight: normal;
      font-size: 1.75em;
      text-align: center;
    }

    input[type="email"] {
      margin-bottom: -1px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    input[type="password"] {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      margin-bottom: 1em;
    }

    .btn {
      width: 100%;
      display: block;

      .spinner-border {
        width: 1em;
        height: 1em;
      }
    }
  }
}

.ACD-queues-endpoints-list {
  list-style-type: none;
  padding-left: 0;
}
.ACD-queues-endpoints-entry {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr max-content;
  gap: 1em;

  .btn-group {
    align-self: end;
    padding-bottom: 1rem;
  }
}

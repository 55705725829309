.ACD-call-state {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
}

.ACD-call-state-line {
  ul {
    list-style-type: none;
    padding-left: 0;
  }
}

.ACD-call-state-entry {
  padding: 0.25em;

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas:
    "target duration"
    "state state";

  h3 {
    font-size: 1em;
    font-weight: normal;
    grid-area: target;
    margin-bottom: 0;
    line-height: 1;
  }

  .call-duration {
    text-align: right;
    grid-area: duration;
    line-height: 1;
  }

  .call-group-state {
    grid-area: state;
    font-size: 0.8em;
    line-height: 1;
  }

  &.ALERT,
  &.SETUP,
  &.SETUP_ACK,
  &.CALL_PROC {
    @include acd_alert("warning");
    border-radius: 0;
  }

  &.CONNECT {
    @include acd_alert("success");
    border-radius: 0;
  }

  &.DISCONNECT_SENT,
  &.DISCONNECT_RECEIVED {
    @include acd_alert("danger");
    border-radius: 0;
  }

  &.PARKED {
    @include acd_alert("secondary");
    border-radius: 0;
  }
}

.ACD-call-state-entry.has-debug {
  grid-template-areas:
    "target duration"
    "state debug";

  button {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    grid-area: debug;
    text-align: right;
  }
}

.ACD-call-state-entry:first-of-type {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.ACD-call-state-entry:last-of-type {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.ACD-dashboards-status-calls .ACD-call-state-entry {
  border-radius: $border-radius;
}

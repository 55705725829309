.ACD-project-users-list {
  list-style-type: none;
  padding-left: 0;
}

.ACD-project-users-list-item {
  display: grid;
  grid-template-columns: 1fr max-content;
  padding-bottom: 0.125em;
  max-width: 40em;
}

.ACD-project-users {
  margin-bottom: 2rem;
}

.TT-form-actions {
  border-top: $border-width solid $border-color;
  margin-top: 1.5em;
  padding-top: 1em;
  margin-bottom: 3em;

  button,
  a {
    margin-right: 0.25em;
  }
}

.TT-error-feedback {
  width: 100%;
  margin-top: $form-feedback-margin-top;
  font-size: $form-feedback-font-size;
  color: $form-feedback-invalid-color;
}

.form-group {
  margin-bottom: 1rem;

  &.is-invalid {
    color: $form-feedback-invalid-color;
  }
  &.is-valid {
    color: $form-feedback-valid-color;
  }
}

.ACD-date-time-picker {
  @extend .form-control;

  .react-date-picker__wrapper,
  .react-datetime-picker__wrapper {
    border: none;
    align-items: center;
  }
}

@use "sass:math";

@mixin acd_alert(
  $variant,
  $color-scale: $alert-color-scale,
  $bg-scale: $alert-bg-scale,
  $border-scale: $alert-border-scale
) {
  $value: map-get($theme-colors, $variant);
  $alert-background: shift-color($value, $bg-scale);
  $alert-border: shift-color($value, $border-scale);
  $alert-color: shift-color($value, $color-scale);

  @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
    $alert-color: mix($value, color-contrast($alert-background), math.abs($color-scale));
  }

  color: $alert-color;
  background-color: $alert-background;
  border: $alert-border;
  @include border-radius($alert-border-radius);

  .alert-link {
    color: shade-color($alert-color, 20%);
  }
}

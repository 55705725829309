.ACD-dashboards-status-overview {
  display: grid;
  grid-template-areas:
    "dashboard-status-overview dashboard-status-details"
    "dashboard-status-states dashboard-status-states";
  grid-template-columns: 50% 50%;
}

.ACD-dashboards-status-overview-main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-area: dashboard-status-overview;

  .ACD-dashboards-status-overview-entry-value {
    font-size: 3em;
  }
}

.ACD-dashboards-status-overview-details {
  display: grid;
  grid-area: dashboard-status-details;
  grid-template-columns: 1fr 1fr 1fr;
}

.ACD-dashboards-status-overview-states {
  grid-area: dashboard-status-states;
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
}

.ACD-dashboards-status-overview-states-status {
  display: block;
}

.ACD-dashboards-status-overview-entry-label {
  display: block;
}

.ACD-dashboards-status-overview-entry-label {
  display: block;
}

.ACD-dashboards-status-overview-entry-value {
  font-size: 1.5em;
}

.ACD-dashboards-status-calls,
.ACD-dashboards-status-reachability {
  background-color: $light;
  padding: $container-padding-x;
  margin: $container-padding-x 0;
  border-radius: $border-radius;
  min-height: 30vh;
}

.ACD-dashboards-status-calls {
  ul {
    list-style-type: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
  }

  .ACD-no-data {
    text-align: left;
  }
}

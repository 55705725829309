html,
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;

  header {
    flex: 0 0 auto;
  }

  main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }
}

.ACD-page-header {
  img {
    max-height: 2em;
    margin-top: -0.8125rem;
    margin-bottom: -0.8125rem;
  }

  .user-button,
  .user-button:active,
  .user-button:focus {
    background-color: transparent;
    color: inherit !important;
    border: none;
  }

  .dropdown {
    margin-left: auto;
  }
}

.ACD-subnav {
  margin-top: 1rem;
  display: block;
}

.ACD-sidebar {
  padding-top: 1rem;
}

.ACD-sub-sub-nav {
  margin-top: 0.25rem;

  .btn-group {
    margin-top: 0;
    margin-right: 0.5em;
    margin-bottom: 0.1em;
  }

  .btn-group:last-of-type {
    margin-right: 0;
  }
}

.ACD-pagination {
  justify-content: center;
}

.ACD-no-data {
  font-style: italic;
  text-align: center;
}

.ACD-numeric-value {
  text-align: right;
}

.ACD-table-index-action-column {
  text-align: right;

  .btn {
    white-space: nowrap;
  }
}

.ACD-loading {
  width: 100%;
  height: 100%;
  line-height: 100%;
  text-align: center;
  vertical-align: middle;
  color: #000;

  &.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    display: flex;
    justify-content: center;
    align-items: center;

    * {
      width: 10vw;
      height: 10vw;
    }
  }

  &.stretch {
    padding-top: 10vh;
    padding-bottom: 10vh;
    height: 10vh;

    * {
      width: 5vw;
      height: 5vw;
    }
  }

  &.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  &.translucent,
  &.backdrop.translucent {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
